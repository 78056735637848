import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Home";
import Attendance from "./Attendance";
import AttendanceHistory from "./AttendanceHistory";
import AttendanceAll from "./AttendanceAll";
import Users from "./Users";
import Login from "./Login";
import React, { Component } from "react";
import axios from "axios";
import { APIURL, TOKEN } from "./utils/constant";
import { accessrole } from "./utils/accessrole";
import Config from "./Config";
import NotFound from "./NotFound";
import Jurnal from "./Jurnal";

axios.defaults.headers.common["Authorization"] = TOKEN;

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: [],
    };
  }

  componentDidMount() {
    this.getUser();
  }

  async getUser() {
    await axios.get(APIURL + "/user").then((resp) => {
      this.setState({ user: resp.data });
    });
  }

  render() {
    const { user } = this.state;

    return (
      <BrowserRouter>
        <div className="App">
          <div className="wrapper">
            <div className="preloader flex-column justify-content-center align-items-center">
              <img
                className="animation__shake"
                src="dist/img/man-fakfak.png"
                alt="MAN Fakfak"
                height="60"
                width="60"
              />
            </div>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route exact path="/dashboard" element={<Home />} />
              <Route path="/attendance" element={<Attendance />} />
              <Route
                path="/attendance-history"
                element={<AttendanceHistory />}
              />
              {/* Role Validation */}
              {accessrole(user.role, "attendance-all") && (
                <Route path="/attendance-all" element={<AttendanceAll />} />
              )}
              {accessrole(user.role, "users") && (
                <Route path="/users" element={<Users />} />
              )}
              {accessrole(user.role, "config") && (
                <Route path="/config" element={<Config />} />
              )}
              {accessrole(user.role, "journal") && (
                <Route path="/journal" element={<Jurnal />} />
              )}
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}
