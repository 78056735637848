import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardUser,
  faFingerprint,
  faGears,
  faMagnifyingGlassArrowRight,
  faTachometerAlt,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { APIURL, TOKEN, USBIN } from "../utils/constant";
import axios from "axios";
import { accessrole } from "../utils/accessrole";

axios.defaults.headers.common["Authorization"] = TOKEN;

export default class SidebarComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: [],
    };
  }

  componentDidMount() {
    if (!USBIN) window.location.href = "/login";
    this.getUser();
  }

  async getUser() {
    await axios.get(APIURL + "/user").then((resp) => {
      this.setState({ user: resp.data });
    });
  }

  render() {
    const { active } = this.props;
    const { user } = this.state;

    return (
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* {!USBIN && <Navigate to={"/login"} />} */}
        <Link to="/" className="brand-link">
          <img
            src="dist/img/man-fakfak.png"
            alt="MAN Fakfak"
            className="brand-image img-circle elevation-3"
            style={{ opacity: "0.8" }}
          />
          <span className="brand-text font-weight-light">
            <strong>Absen Online</strong> <small>v.2</small>
          </span>
        </Link>

        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* General Menu */}
              <li className="nav-item">
                <Link
                  to="/dashboard"
                  className={active === "home" ? "nav-link active" : "nav-link"}
                >
                  <FontAwesomeIcon
                    icon={faTachometerAlt}
                    className="nav-icon"
                  />
                  <p>Dashboard</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/attendance"
                  className={
                    active === "attendance" ? "nav-link active" : "nav-link"
                  }
                >
                  <FontAwesomeIcon icon={faFingerprint} className="nav-icon" />
                  <p>Absen</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/attendance-history"
                  className={
                    active === "attendance-history"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <FontAwesomeIcon
                    icon={faClipboardUser}
                    className="nav-icon"
                  />
                  <p>Riwayat Absen</p>
                </Link>
              </li>

              {/* Semi Admin Menu */}
              {accessrole(user.role, "attendance-all") && (
                <li className="nav-item">
                  <Link
                    to="/attendance-all"
                    className={
                      active === "attendance-all"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faMagnifyingGlassArrowRight}
                      className="nav-icon"
                    />
                    <p>Riwayat Absen Pegawai</p>
                  </Link>
                </li>
              )}

              {/* Full Admin Menu */}
              {accessrole(user.role, "users") && (
                <li className="nav-item">
                  <Link
                    to="/users"
                    className={
                      active === "users" ? "nav-link active" : "nav-link"
                    }
                  >
                    <FontAwesomeIcon icon={faUserAlt} className="nav-icon" />
                    <p>Users</p>
                  </Link>
                </li>
              )}

              {accessrole(user.role, "config") && (
                <li className="nav-item">
                  <Link
                    to="/config"
                    className={
                      active === "config" ? "nav-link active" : "nav-link"
                    }
                  >
                    <FontAwesomeIcon icon={faGears} className="nav-icon" />
                    <p>Config</p>
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}
