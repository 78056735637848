import React, { Component } from "react";
import { Container } from "react-bootstrap";
import HeaderContentComp from "./components/HeaderContentComp";
import SidebarComp from "./components/SidebarComp";

export default class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      breadcrumb: ["Dashboard"],
    };
  }

  render() {
    const { breadcrumb } = this.state;
    return (
      <div>
        <SidebarComp active="users" />
        <div className="content-wrapper">
          <Container fluid>
            <HeaderContentComp title="Users" breadcrumb={breadcrumb} />
            <div className="content"></div>
          </Container>
        </div>
      </div>
    );
  }
}
