import React, { Component } from "react";

export default class HeaderContentComp extends Component {
  render() {
    const { title, breadcrumb } = this.props;
    return (
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">{title}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <strong>Absen Online - MAN Fakfak</strong>
                </li>
                {breadcrumb.map((crumb, index) => (
                  <li key={index} className={breadcrumb.length-1 === index ? "breadcrumb-item active" : "breadcrumb-item"}>{crumb}</li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
