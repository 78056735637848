import React, { useState } from "react";
import { Badge, Pagination, Table } from "react-bootstrap";
import moment from "moment-timezone";
import "moment/locale/id";

moment.locale("id");

const AttendanceTableComp = ({ name, attendances, count, show = 25 }) => {
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const pages = count > show ? Math.ceil(count / show) : 1;

  const renderPaginationItems = () => {
    let child = [];
    for (let i = 1; i <= pages; i++) {
      child.push(
        <Pagination.Item
          key={i}
          active={i === page}
          onClick={() => {
            changePage(i);
          }}
        >
          {i}
        </Pagination.Item>
      );
    }
    return child;
  };
  const changePage = (change) => {
    setPage(change);
    setOffset((change - 1) * show);
  };
  const First = () => {
    setPage(1);
    setOffset(0);
  };
  const Next = () => {
    setPage(page + 1);
    setOffset(offset + show);
  };
  const Prev = () => {
    setPage(page - 1);
    setOffset(offset - show);
  };
  const Last = () => {
    setPage(pages);
    setOffset((pages - 1) * show);
  };

  return (
    <div className="bg-white rounded shadow p-4">
      <div className="float-right text-end">
        <small>
          Terlambat <Badge bg="warning"> </Badge>
        </small>
        <br />
        <small>
          Hari ini <Badge bg="info"> </Badge>
        </small>
      </div>

      <Pagination size="sm">
        <Pagination.First disabled={page === 1} onClick={First} />
        <Pagination.Prev disabled={page === 1} onClick={Prev} />
        {renderPaginationItems()}
        <Pagination.Next disabled={page === pages} onClick={Next} />
        <Pagination.Last disabled={page === pages} onClick={Last} />
      </Pagination>

      <Table size="sm" responsive="md" bordered>
        <thead className="text-center">
          <tr>
            <th className="align-middle" rowSpan={2}>
              #
            </th>
            <th className="align-middle text-start" rowSpan={2}>
              Tanggal
            </th>
            <th className="align-middle text-start" rowSpan={2}>
              Email
            </th>
            {name ? (
              <th className="align-middle" rowSpan={2}>
                Nama
              </th>
            ) : (
              <th className="align-middle" rowSpan={2}>
                Username
              </th>
            )}
            <th className="align-middle" colSpan={2}>
              Waktu Absen
            </th>
            <th className="align-middle" colSpan={3}>
              Keterangan
            </th>
          </tr>
          <tr>
            <th className="align-middle">Masuk</th>
            <th className="align-middle">Keluar</th>
            <th className="align-middle">Terlambat</th>
            <th className="align-middle">Durasi Kerja</th>
            <th className="align-middle" style={{ width: "30%" }}>
              Pekerjaan
            </th>
          </tr>
        </thead>
        <tbody className="text-center">
          {attendances ? (
            attendances.slice(offset, page * show).map((attendance, index) => (
              <tr
                key={index}
                className={
                  moment(attendance.tanggal).isSame(moment(), "day")
                    ? "bg-info"
                    : ""
                }
              >
                <td>{index + offset + 1}</td>
                <td className="text-start">
                  {moment(attendance.tanggal).format("dddd, DD MMMM YYYY")}
                </td>
                <td className="text-start">{attendance.email}</td>
                {name ? (
                  <td className="text-start">{attendance.nama}</td>
                ) : (
                  <td className="text-start">{attendance.username}</td>
                )}
                <td>{attendance.masuk}</td>
                <td>{attendance.keluar}</td>
                <td
                  className={attendance.terlambat === "-" ? "" : "bg-warning"}
                >
                  {attendance.terlambat}
                </td>
                <td>{attendance.durasi}</td>
                <td>{attendance.pekerjaan}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={9}>Belum ada Riwayat Absen</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default AttendanceTableComp;
