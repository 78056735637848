import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIURL } from "../utils/constant";

const TimeSet = ({column}) => {
  const [time, setTime] = useState([]);

  useEffect(() => {
    getTime();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTime = async () => {
    await axios
      .get(APIURL + "config/" + column)
      .then((resp) => {
        setTime(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <span>
      {time.label} : <strong>{time.value}</strong>
    </span>
  );
};

export default TimeSet;
