import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import HeaderComp from "./components/HeaderComp";
import SidebarComp from "./components/SidebarComp";
import HeaderContentComp from "./components/HeaderContentComp";
import axios from "axios";
import { APIURL, TOKEN } from "./utils/constant";
import swal from "sweetalert";
import TimeSet from "./components/TimeSet";
import moment from "moment-timezone";
import "moment/locale/id";

moment.locale("id");
axios.defaults.headers.common["Authorization"] = TOKEN;

const Attendance = () => {
  const breadcrumb = ["Absen"];
  const today = moment().format("dddd");
  const morning = moment().hour() < 11 ? true : false;
  const [now, setNow] = useState("");
  const [work, setWork] = useState("");
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    const idInterval = setInterval(() => {
      setNow(moment().format("hh:mm:ss"));
    }, 1000);
    return () => clearInterval(idInterval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const absent = async () => {
    setSpinner(true);
    if (!morning && work.trim() === "") {
      swal({
        title: "Maaf",
        text: "Kaka harus isi pekerjaan hari ini!",
        icon: "warning",
      });
    } else {
      await axios
        .post(APIURL + "absent", {
          pagi: morning ? 1 : 0,
          locale: moment.locale(),
          timezone: moment.tz.guess(),
          pekerjaan: work,
        })
        .then((resp) => {
          setSpinner(false);
          swal({
            title: "Sukses!",
            text: resp.data,
            icon: "success",
            buttons: "Ok!",
          });
        })
        .catch((err) => {
          setSpinner(false);
          swal({
            title: "Maaf!",
            text: err.response.data.messages.error,
            icon: "warning",
            buttons: "Ok!",
          });
        });
    }
    setWork("");
  };

  return (
    <div>
      <HeaderComp />
      <SidebarComp active="attendance" />
      <div className="content-wrapper">
        <Container fluid>
          <HeaderContentComp title="Absen" breadcrumb={breadcrumb} />
          <div className="content">
            <Row className="justify-content-sm-center">
              <Col md="5" lg="6">
                <Card className="text-center">
                  <Card.Header>
                    <h3>
                      <strong>
                        {morning ? "Absen Masuk" : "Absen Pulang"}
                      </strong>
                    </h3>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text className="text-start text-muted">
                      <small>
                        Hari : <strong>{today}</strong>
                      </small>
                      <br />
                      <small>
                        <TimeSet column={today.toLowerCase() + "-in"} />
                      </small>
                      <br />
                      <small>
                        <TimeSet column={today.toLowerCase() + "-out"} />
                      </small>
                    </Card.Text>
                    <hr />
                    <Card.Text>
                      <span className="text-muted">Jam Sekarang</span>
                      <br />
                      <strong style={{ fontSize: "8vw" }}>
                        {now ? now : "00:00:00"}
                      </strong>
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer className="d-grid">
                    {!morning && (
                      <Form.Control
                        type="text"
                        placeholder="Pekerjaan"
                        value={work}
                        required
                        name="work"
                        onChange={(evt) => {
                          setWork(evt.target.value);
                        }}
                      />
                    )}
                    <Button
                      variant="primary"
                      className={
                        spinner
                          ? "btn btn-primary btn-block disabled"
                          : "btn btn-primary btn-block"
                      }
                      onClick={absent}
                    >
                      Absen Sekarang
                      {spinner && (
                        <Spinner
                          className="ms-3"
                          size="sm"
                          animation="border"
                        />
                      )}
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Attendance;
