import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import AttendanceTableComp from "./components/AttendanceTableComp";
import HeaderComp from "./components/HeaderComp";
import HeaderContentComp from "./components/HeaderContentComp";
import SidebarComp from "./components/SidebarComp";
import { APIURL, TOKEN } from "./utils/constant";

axios.defaults.headers.common["Authorization"] = TOKEN;

const AttendanceHistory = () => {
  const breadcrumb = ["Absen", "Riwayat Absen"];
  const [attenDatas, setAttenDatas] = useState();
  const [count, setCount] = useState();

  useEffect(() => {
    getAttendance();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAttendance = async () => {
    await axios.get(APIURL + "absent").then((resp) => {
      setAttenDatas(resp.data);
      setCount(resp.data.length);
    });
  };

  return (
    <div>
      <HeaderComp />
      <SidebarComp active="attendance-history" />
      <div className="content-wrapper">
        <Container fluid>
          <HeaderContentComp title="Riwayat Absen" breadcrumb={breadcrumb} />
          <div className="content bg-white rounded shadow-sm">
            <AttendanceTableComp attendances={attenDatas} count={count} />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default AttendanceHistory;
