import axios from "axios";
import jsCookie from "js-cookie";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { APIURL, TOKEN } from "../utils/constant";

const HeaderComp = () => {
  const [user, setUser] = useState([]);

  useEffect(() => {
    getUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = async () => {
    await axios
      .get(APIURL + "user", {
        headers: {
          Authorization: TOKEN,
        },
      })
      .then((resp) => {
        setUser(resp.data);
      })
      .catch((err) => {
        if (err.response.data.code === 500) {
          swal({
            title:
              "Terjadi Kesalahan pada server! Silahkan Coba kembali beberapa saat lagi",
            text: "Pesan: " + err.response.data.message,
            icon: "error",
            buttons: "Ok!",
          }).then(() => {
            Logout();
          });
        }
      });
  };

  const Logout = () => {
    jsCookie.remove("usbin");
    window.location.href = "/login";
  };

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="pushmenu"
            href="/#"
            role="button"
          >
            <i className="fas fa-bars"></i>
          </a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <a
            href="https://magefa.sch.id"
            target={"_blank"}
            rel="noreferrer"
            className="nav-link"
          >
            <h5>MAN Fakfak</h5>
          </a>
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item shadow-sm rounded border border-2">
          <span className="nav-link pointer" data-toggle="dropdown" href="#">
            Hi, <strong>{user.username}</strong>
            <i className="fas text-primary fa-cogs ms-2"></i>
          </span>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <div className="dropdown-divider"></div>
            <span className="dropdown-item pointer">
              <i className="fas fa-unlock mr-2"></i> Ganti Password
            </span>
            <div className="dropdown-divider"></div>
            <span className="dropdown-item pointer" onClick={Logout}>
              <i className="fas fa-sign-out-alt mr-2"></i> Logout
            </span>
          </div>
        </li>
        <li className="nav-item shadow-sm rounded border border-2">
          <span className="nav-link" data-widget="fullscreen" role="button">
            <i className="fas fa-expand-arrows-alt"></i>
          </span>
        </li>
      </ul>
    </nav>
  );
};

export default HeaderComp;
