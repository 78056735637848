import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const NotFound = () => {
  return (
    <Container fluid className="py-5">
      <Row className="justify-content-center my-5">
        <Col sm={3}>
          <div className="text-center shadow bg-light p-4 rounded">
            <img
              className="fluid responsive w-50 my-2"
              src="./dist/img/man-fakfak.png"
              alt="Logo MAN Fakfak"
            ></img>
            <h3 className="my-2">
              <strong>OPPS!</strong>{" "}
              <span className="text-warning">Page Not Found</span>
            </h3>
            <p className="my-2 text-muted">
              Maaf! Sepertinya halaman yang Anda cari tidak ditemukan, telah
              dihapus, diganti, atau sementara tidak tersedia.
            </p>
            <a href="/" className="my-2 text-decoration-none text-white rounded py-1 px-2 bg-info">Kembali ke Halaman utama</a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
