import axios from "axios";
import jsCookie from "js-cookie";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { APIURL } from "./utils/constant";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errormsg, setErrorMsg] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const doLogin = async (evt) => {
    evt.preventDefault();
    setSpinner(true);

    await axios
      .post(APIURL + "login", {
        email: email,
        password: password,
      })
      .then((resp) => {
        setErrorMsg([]);
        jsCookie.set("usbin", resp.data.token, { expires: 3 });
        swal({
          title: resp.data.msg,
          text: "Klik Ok untuk mengalihkan ke halaman Absen",
          icon: "success",
          button: "Ok!",
        }).then(() => {
          setSpinner(false);
          window.location.href = "/";
        });
      })
      .catch((err) => {
        if (err.response.data.code === 500) {
          swal({
            title:
              "Terjadi Kesalahan pada server! Silahkan Coba kembali beberapa saat lagi",
            text: "Pesan: " + err.response.data.message,
            icon: "error",
            buttons: false,
          });
        } else {
          setErrorMsg(err.response.data.messages);
        }
        setSpinner(false);
      });
  };

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <span className="h1">
              <b>Absen Online</b>
              <h6>MAN Fakfak</h6>
            </span>
          </div>
          <div className="card-body">
            <p className="login-box-msg">
              {errormsg["error"] ? (
                <span className="text-danger">{errormsg["error"]}</span>
              ) : (
                "Login untuk memulai sesi"
              )}
            </p>

            <form onSubmit={doLogin}>
              <div className="input-group mb-3">
                <input
                  type="email"
                  className={
                    errormsg["email"]
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Email"
                  value={email}
                  onChange={(evt) => setEmail(evt.target.value)}
                  aria-describedby="err-email"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
                <span id="err-email" className="error invalid-feedback">
                  {errormsg["email"]}
                </span>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className={
                    errormsg["password"]
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Password"
                  value={password}
                  onChange={(evt) => setPassword(evt.target.value)}
                  aria-describedby="err-password"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
                <span id="err-password" className="error invalid-feedback">
                  {errormsg["password"]}
                </span>
              </div>
              <div className="row px-2">
                <button
                  type="submit"
                  className={
                    spinner
                      ? "btn btn-primary btn-block disabled"
                      : "btn btn-primary btn-block"
                  }
                >
                  Sign In
                  {spinner && (
                    <Spinner className="ms-3" size="sm" animation="border" />
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
